<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 14 14">
    <path
      :style="iconStyle"
      d="M12.25-12.25H1.75A1.752,1.752,0,0,0,0-10.5v7.875A1.752,1.752,0,0,0,1.75-.875H4.375v2.3A.327.327,0,0,0,4.7,1.75.314.314,0,0,0,4.9,1.684L8.313-.875H12.25A1.752,1.752,0,0,0,14-2.625V-10.5A1.752,1.752,0,0,0,12.25-12.25Zm.438,9.625a.439.439,0,0,1-.437.438H7.875l-.35.262L5.688-.547V-2.187H1.75a.439.439,0,0,1-.437-.437V-10.5a.439.439,0,0,1,.438-.437h10.5a.439.439,0,0,1,.438.438Z"
      transform="translate(0 12.25)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '14',
    },
    width: {
      type: String,
      default: '14',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
